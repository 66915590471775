import React from "react";
import ScrollToTop from "react-scroll-up";
import TextLoop from "react-text-loop";
import FooterEmpty from "../component/footer/FooterEmpty";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import TabThree from "../elements/tab/TabThree";
import Helmet from "../component/common/Helmet";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Welcome to the matrix driven world",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

const DesignerPortfolioModified = () => {
  return (
    <div>
      <Helmet pageTitle="Atmatrix Interactive" />
      {/* Start Header Area  */}
      {/* <Header headerPosition="header--static" logo="symbol-dark" color="color-black"/> */}
      {/* End Header Area  */}

      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide designer-portfolio slider-style-3 d-flex align-items-center justify-content-center bg_color--5 rn-slider-height"
            key={index}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="designer-thumbnail">
                    <img
                      src="/assets/images/about/atmatrix_logo.png"
                      alt="Atmatrix"
                    />
                  </div>
                </div>
                {/* <div className="col-lg-7 mt_md--40 mt_sm--40">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    <h2 className="title">Atmatrix Interactive</h2>
                    <h1 className="title">
                      <TextLoop>
                        <span> Web Development.</span>
                        <span> User Interfaces.</span>
                        <span> 3D Graphics.</span>
                      </TextLoop>
                    </h1>

                    <h2>Kochi, India.</h2>
                    {value.description ? (
                      <p className="description">{value.description}</p>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          href={`${value.buttonLink}`}
                        >
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
        <div className="slide-v-filler"> </div>
      </div>

      {/* Start Designer Portfolio  */}
      {/* <div className="designer-portfolio-area ptb--120 bg_color--1">
                <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                    <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
                </div>
            </div> */}
      {/* End Designer Portfolio  */}

      {/* Start Footer Style  */}
      <FooterEmpty />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default DesignerPortfolioModified;
